* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

p  {
  margin-bottom: 1.5em

}

body {
  font-family: 'Poppins', sans-serif;
  width: auto;

  max-width: 1000px;

  padding-left: 0;
  padding-right: 0;

  margin-left: auto;
  margin-right: auto;
}

@media (min-width:768px) {
header {
  display: flex;
  width: 90%;
  margin: auto;
}
}

header .logo-container {
  /* //background: lightblue; */
  flex: 1;
}

header .main-header {
  /* background: lightgreen; */
  flex: 6;
  text-align: center;
}
footer {
  text-align: center;
}

footer p {
  margin: 25px;
}
@media (min-width:480px) {
  .main-container {
      display: flex;
  }
}


.main-container .items {
  flex: 6;
}

.main-container .ad-container {
  flex: 2;
}
.item-container { 
  margin: 20px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important; 
}

.item-container div {
  margin: 10px 0px;
  padding: 0px 25px;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  padding: 7px 20px;
  cursor: pointer;  
}
.btn:hover {
  background: #666;
}